#navBar {
	position: fixed;
	z-index: 1;
	top:-200px;
	/*transition: top .4s;*/
	background: rgba(53,47,47,0.9);
}
a{
	cursor: pointer;
}
#entries > a {
	color: white;
}
#entries > a > img {
	width:20px;
	height: auto;
	filter: invert(100%);
}
