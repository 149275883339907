.container2 {
	height: 100vh;
	overflow: auto;
	background: #3498db;
    font-family: 'Indie Flower', Helvetica,Arial,'sans-serif';
 	font-weight:400;
 	color: #fff;
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	align-content: center;
 	flex-direction: column;
}

#snow-target {
	position: relative;
	overflow: hidden;
}
.header2 {/*
	margin-top: 30%;
	margin-left: 10%;*/
	cursor: default;
}

h1{
	font-family: 'Indie Flower', Helvetica;
}

h2{
	animation-delay: 1s;
}

img{
	width: 50px;
	height: auto;
	vertical-align: bottom;
	animation-delay: 2s;
	cursor: pointer;
}
#welcome {
	z-index: -1;
}