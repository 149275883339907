.BackToTop{
    height: 30px;
    position: fixed;
    bottom: 20px;
    width: 30px;
    transition: opacity 0.5s linear 0s, right;
    cursor: pointer;
    opacity: 1;
    right: 30px;
    z-index: 1000;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
	background-color: rgba(255, 255, 255, 0.73);
	border-radius: 15px;
	padding-top: 10px;
}
.doNotDisplay{
    display: none;
}
.doDisplay{
    display: unset;
}

.arrowUp{
	border: solid rgba(0, 0, 0, 0.9);
	border-width: 0 4px 4px 0;
	display: inline-block;
	padding: 4px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}