.App {
  text-align: center;
}

::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
}
::-webkit-scrollbar-thumb {
    background: white; 
}
