
#footer {
}
#contact-head{
	cursor: default;
}
#contactMe {
	margin-top: 25vh;
	height: 16.5vh;
	overflow: hidden;
	/*transform: skewY(7deg);*/
	background: rgba(53,47,47,1);

}

#contactMe-container {
	/*transform: skewY(-7deg);*/
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	align-content: center;
	vertical-align: middle;
}

#rectify{
	background: rgba(53,47,47,1);
}

#mail, #phone{
	fill: #aeacac;
}
.linebreak{
	width: 100%;
}
#centerfoot{

}
span{
	display: none;
}
@media screen and (max-width: 1020px){
	#contactMe{
		height: 20vh;
	}
}