 #projectsSection {
	min-height: 1800px;
	overflow: auto;
	transform: skewY(-7deg);
	background: #9DE0AD;
	-webkit-transform-origin: 50%  53%;	
	/*filter: contrast(1.5);*/
}

#projectsSection-container {
	transform: skewY(7deg);
	-webkit-transform-origin: 50%  53%;	
	/*filter: saturate(1.2);*/
	font-size: 1.1rem;
}
#projectsSection-cards{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	color: white;
	/*filter: contrast(0.67);*/
	/*filter: saturate(0.83); */
}
.projectHead{
	cursor: default;
}
.projectPic{
	cursor: default;
	width: 320px;

}
.card{
	width: 350px;
}

/*@media screen and (max-width: 1565px){
	#projectsSection{
		height: 200vh;
	}
}

@media screen and (max-width: 1200px){
	#projectsSection{
		height: 260vh;
	}
}

@media screen and (max-width: 800px){
	#projectsSection{
		height: 410vh;
	}
}

@media screen and (max-width: 500px){
	#projectsSection{
		height: 650vh;
	}
}*/